<template>
<section class="content">
    <div class="flex justify-content-center">
    <div class="w-100 card">
        <div class="card-body">
        <div class="row">
            <div class="card-deck">
                <div class="card shadow-sm">
                    <div class="card-body">
                    <h5 class="card-title mb-1">{{memberShip.title}}</h5>
                    <p class="card-text text-wrap mb-4" style="max-width:30rem;">{{memberShip.description}}</p>
                    <router-link :to="{ path: '/settings/membertype' }"
    class="btn btn-secondary">Kembali</router-link>
                    <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="p-1"></div>
        <div class="table-responsive">
            <table class="table table-hover" ref="tblmember">
            <thead>
            <tr>
                <th>KURIR</th>
                <th>LAYANAN</th>
                <th>NOTE</th>
                <th>PERCENT CASHBACK</th>
            </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            </table>
        </div>
        </div>
    </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <form role="form" @submit.prevent="submitForm">
            <div class="modal-header">
            <h5 class="modal-title">Form</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
			<p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
				<b>Please correct the following error(s):</b>
				<ul>
					<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
				</ul>
				<button type="button" class="close" data-dismiss="alert" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</p>
            <input type="hidden" name="membership_type_id" v-model="form.membership_type_id">
            <div class="row">
                <div class="col-md-12 form-group">
                <label class="control-label">Layanan</label>
                <v-select :options="courierService" v-model="form.courier_service_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-12 form-group">
                <label class="control-label">Percent Cashback</label>
                <input class="form-control" v-model="form.percent_cashback" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="percent_cashback" />
                </div>
            </div>
            </div>
            <div class="modal-footer">
            <button type="submit" class="btn btn-primary">
                Save changes
            </button>
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
            >
                Close
            </button>
            </div>
        </form>
        </div>
    </div>
    </div>
</section>
<!-- /.content -->
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import "vue-select/dist/vue-select.css";

export default {
name: "DetailMember",
data() {
    return {
    //   dateStart: "",
    memberShip: {},
    courierService: [],
    errors: [],
    method: "",
    form: {
        membership_type_id: "",
        percent_cashback: "",
        courier_service_id: "",
    },
    };
},
components: {
    vSelect,
},
created: function () {
    // this.roles = this.$route.meta.roles;
    // console.log("load initial data", this.$route);
    this.detailMember(this.$route.params.id);
    this.loadService();
},
methods: {
    loadService: function () {
    authFetch("/master/membertype/service")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.courierService = js.data;
        });
    },
    detailMember: function (id) {
    authFetch("/master/membertype/" + id).then((res) => {
        res.json().then((json) => {
        this.memberShip = json;
        });
    });
    },
    submitForm: function (ev) {
    const e = this.$refs;
    this.form.membership_type_id = this.$route.params.id; 
    if (!this.form.courier_service_id) {
        return Swal.fire("Layanan belum dipilih!", ``, "error");
    }
    var data = Object.keys(this.form)
    .map(
        (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
    )
    .join("&");
    var urlSubmit = "/master/membertype/detail";
    if (this.method == "PUT")
    urlSubmit = "/master/membertype/detail/" + this.form.id;
    authFetch(urlSubmit, {
    method: this.method,
    body: data,
    })
    .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
    })
    .then((js) => {
        this.errors = [];
        if (!js.success) {
        return Swal.fire(js.msg, ``, "error");
        // console.log(js.details);
        // for (var key in js.details) {
        //     if (js.details.hasOwnProperty(key)) {
        //     this.errors.push(js.details[key]);
        //     }
        // }
        // return;
        }
        this.tbl.api().ajax.reload();
        $(e.formDialog).modal("hide");
    });

    ev.preventDefault();
    },
    handleClick(e) {},
},
mounted() {
    const e = this.$refs;
    const self = this;
    this.tbl = createTable(e.tblmember, {
    title: "",
    ajax: "/master/membertype/detail/" + this.$route.params.id,
    roles: this.$route.params.roles,
    serverSide: true,
    frame: true,
    processing: true,
    language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
    displayLength: 10,
    lengthChange: true,
    sort:false,
    paramData: function (d) {},
    columns: [
        { data: "nama" },
        { data: "code",
        render: function(data, type, row, meta){
            return `${row.code} (${row.service_code})`;
        }, },
        { data: "note" },
        { data: "percent_cashback" ,
        render: function(data, type, row, meta) {
            return data + "%";
        }
        },
    ],
    // filterBy: [2, 7],
    filter: false,
    rowCallback: function (row, data) {
    },
    buttonClick: (evt) => {
        if (evt.role == "create") {
        self.form = {};
        self.method = "POST";
        self.errors = [];
        // self.formTitle = "Tambah Jenis Membership";
        $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
        self.form = evt.data;
        self.method = "PUT";
        self.errors = [];
        // self.formTitle = "Edit Jenis Membership";
        $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
        // self.form = evt.data;
        console.log(evt);
        Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
        }).then((result) => {
            if (result.isDenied) {
            authFetch("/master/membertype/detail/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
            })
                .then((res) => {
                return res.json();
                })
                .then((js) => {
                this.tbl.api().ajax.reload();
                });
            }
        });
        }
    },
    });
},
};
</script>